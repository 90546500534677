import React from "react";
import "../assets/css/Footer.css";
import logo from "../assets/img/YCGAMINGLOGO.svg";
function FooterBar() {
  return (
    <div className="footer">
      <div className="max-container">
        <div className="footer-contents">
          <div className="footer-item">
            <img height="250px" src={logo} alt="Sicily-Pizzeria-Norwich-logo" />
          </div>
        </div>
        <div className="footer-contents">
          {/* <div className="footer-item">
            <div className="footer-list-header">Social Media &amp; Reviews</div>
            <Social />
          </div> */}
          {/*  */}
          {/* <div className="footer-item">
            <div className="footer-list-header">Address</div>
            <div className="footer-list-item">
              <address>
                Castle Quarter Food Court,
                <br />
                100 Castle Meadow,
                <br />
                Norwich,
                <br />
                Norfolk.
                <br />
                NR1 3DD.
              </address>
            </div>
          </div> */}
          {/* <div className="footer-item">
            <div className="footer-list-header">Business Links</div>
            <div className="footer-list-item">
              &copy; Paolos Lique - Norwich
            </div>
            <div className="footer-list-link">
              <Link to="/privacy-policy/" exact="true">
                Privacy Policy
              </Link>
            </div>
            <div className="footer-list-link">
              <ED />
            </div>
          </div> */}
        </div>
        {/* <Maps /> */}
      </div>
    </div>
  );
}
export default FooterBar;
