import React, { useState } from "react";
import logo from "../assets/img/YCGAMINGLOGO.svg";
import "../assets/css/Navigation.css";
import { Link } from "gatsby";



  export default  ({data}) => {

    return (

    <div className="navbar">
      <div className="max-container">
        <div className="menu-container">
          <div className="menu-branding">
            <Link to="/">
              <img
                src={logo}
                height="240"
                className="d-block align-top left-space"
                alt="Sicily-Market-logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
    );
  };
    




